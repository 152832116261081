import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { environment } from '@env/environment';
import { map, catchError, Observable } from 'rxjs';
import { DeliveryLog, ValidateCode, VehicleBilling } from '../models/vehicle-billing-model';
import { PagedResponse } from '@app/services/griddatasource.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryContractService  extends BaseService {
  private httpAnonymous: HttpClient;

  constructor(private http: HttpClient, handler: HttpBackend) {
    super();
    this.httpAnonymous = new HttpClient(handler);
  }

  protected addApiAuthHeader(id: number, emailOrPhone: string, code: string){
    return new HttpHeaders({
      'X-API-ID': id,
      'X-API-USER': emailOrPhone,
      'X-API-KEY': code
    });
  }
  getVehicleBillingById(
    id: number, emailOrPhone: string, code: string
  ): Observable<VehicleBilling> {
    const headers = this.addApiAuthHeader(id, emailOrPhone, code);

    return this.httpAnonymous
      .get<PagedResponse<VehicleBilling>>(
        `${
          environment.technicalDeliveryServerUrl
        }/delivery-contract/${id}`,
        { headers: headers }
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }
  deliveryVehicleBilling(body: DeliveryLog,id: number, emailOrPhone: string, code: string){
    const headers = this.addApiAuthHeader(id, emailOrPhone, code);
    return this.httpAnonymous
      .post(`${environment.technicalDeliveryServerUrl}/delivery-contract/deliver`,
      body, { headers } )
      .pipe(map(super.extract), catchError(super.serviceError));
  }
  validateCode(vc: ValidateCode) : Observable<ValidateCode>{
    const headers = this.addApiAuthHeader(vc.id, vc.emailOrPhone, vc.code);

    return this.httpAnonymous
      .post<ValidateCode>(`${environment.technicalDeliveryServerUrl}/delivery-contract/validate`, vc , { headers })
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  sendPdf(id: number, body: DeliveryLog, emailOrPhone: string, code: string){
    const headers = this.addApiAuthHeader(id, emailOrPhone, code);
    return this.httpAnonymous
      .post(`${environment.technicalDeliveryServerUrl}/delivery-contract/${id}`, body, { headers })
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getPdf(id: number, emailOrPhone: string, code: string){
    const headers = this.addApiAuthHeader(id, emailOrPhone, code);
    return this.httpAnonymous
    .post(`${environment.technicalDeliveryServerUrl}/delivery-contract/download/${id}`, null, { headers })
    .pipe(map(super.extract), catchError(super.serviceError));
  }

  getLanguage(id: number){
    return this.httpAnonymous
    .get(`${environment.technicalDeliveryServerUrl}/delivery-contract/language/${id}`)
    .pipe(
      map(super.extract),
      catchError(super.serviceError)
    );
  }
}
