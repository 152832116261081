import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { VehicleBilling } from '@app/modules/technical-delivery/models/vehicle-billing-model';
import { VehicleBillingService } from '@app/modules/technical-delivery/services/vehicle-billing.service';
import { GridDataSource } from '@app/services/griddatasource.service';
import { Logger } from '@app/shared';
import { IMasks } from '@app/shared/utils/input-masks';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseFormComponent } from '@app/shared/component/base-form/base-form.component';
import { TourService } from 'ngx-ui-tour-core';
import { catchError, from, map, of, switchMap, tap } from 'rxjs';
import { VehicleClassService } from '@app/modules/technical-delivery/services/vehicle-class.service';
import { VehicleSegment } from '@app/modules/technical-delivery/models/vehicle-segment.model';
import { TechnicalDeliveryService } from '@app/modules/technical-delivery/services/technical-delivery.service';
import { VehicleClass } from '@app/modules/technical-delivery/models/vehicle-class';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractChecklistComponent } from './contract-checklist/contract-checklist.component';
import { VehicleCheckListItem } from '@app/modules/technical-delivery/models/vehicle-checklist';
import { PdfViewerComponent } from 'ng2-pdf-viewer';

import { MonitoringService } from '@app/services/logging.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DeliveryContractService } from '@app/modules/technical-delivery/services/delivery-contract.service';
import { I18nService } from '@app/i18n/service/i18n.service';
var log = new Logger();

@Component({
  selector: 'app-accept-contract',
  templateUrl: './accept-contract.component.html',
  styleUrls: ['./accept-contract.component.scss'],
  providers: [GridDataSource, DecimalPipe, VehicleBillingService]
})

export class AcceptContractComponent extends BaseFormComponent implements OnInit {
  maskToPhone = IMasks.phoneMask;
  maskToChassi = IMasks.chassiMask;
  maskThousands = IMasks.thousandsMask;
  vehicleBillingId: number;
  vehicleClass: VehicleClass;
  vehicleSegment: VehicleSegment;
  valid: boolean = true;
  checkListItens: VehicleCheckListItem[] | null;
  getCheckList = true;
  @ViewChild('content') el!: ElementRef;
  latitude: string;
  longtude: string;
  pdfSource: any;
  viewChecklist: boolean = true;
  disableAccept: boolean = true;
  Response: number = 0;
  isPhone: boolean;
  btnClass: string = "btn btn-outline-primary mb-2";
  isMobile: boolean;
  hasLinkedVehicles: boolean = false;
  checkLinkedVehicles: boolean = false;
  checkReadTerms: boolean = false;
  linkedVehicleString: string = "";
  linkedVehicleIds: number[] = [];
  vehicleBilling: VehicleBilling;
  emailOrPhone: string;
  code: string;
  @ViewChild(PdfViewerComponent, { static: false })
  private pdfComponent: PdfViewerComponent;

  constructor(
    protected injector: Injector,
    public service: GridDataSource<VehicleBilling>,
    private spinner: NgxSpinnerService,
    private deliveryContractService: DeliveryContractService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    public tourService: TourService,
    public translateService: TranslateService,
    private vehicleClassService: VehicleClassService,
    private vehicleSegmentService: TechnicalDeliveryService,
    private modalService: NgbModal,
    private monitoring: MonitoringService,
    private loadingBarService: LoadingBarService,
    private i18nService: I18nService
  ) {
    super(injector);
  }


  ngOnInit(): void {
    super.ngOnInit();
    this.deliveryContractService.getLanguage(this.vehicleBillingId).subscribe(
      (response) => {
        this.i18nService.language = response.language; 
      },
      (error) => {
        console.error('Error fetching language:');
      }
    );
    this.validateCode();
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    this.isMobile = mediaQuery.matches;
    navigator.geolocation.getCurrentPosition(resp => {
      this.latitude = resp.coords.latitude.toString();
      this.longtude = resp.coords.longitude.toString();
    }, err => console.log(err));
  }

  pageRendered() {
    this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
  }

  buildForm() {
  }

  getVehicleBillingById(emailOrPhone: string, code: string) {
    this.deliveryContractService.getVehicleBillingById(this.vehicleBillingId, emailOrPhone, code)
     .subscribe(
        (response) => {
          this.vehicleBilling = response;
          this.hasLinkedVehicles = response.linkedVehicles?.length > 0;
          if (this.hasLinkedVehicles) {
            this.linkedVehicleIds = response.linkedVehicles?.map(x => x.id);
            this.linkedVehicleString = response.linkedVehicles?.map(x => x.chassiFull).join(', ');
          } else {
            this.checkLinkedVehicles = true;
          }
          this.checkListItens = response.checklistsItens;
          this.Response = response.checklistResponse;
          this.checkResponse();
          this.deliveryContractService.getPdf(response.id, this.emailOrPhone, this.code).subscribe((res) => {
            let binary_string = window.atob(res as any);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }
            this.pdfSource = bytes;
            this.loadingBarService.complete();
          })
        },
        (error)=>{
          log.error('Ocorreu um erro no servidor, tente mais tarde.', error);
          this.toastr.error(error.error).onHidden.subscribe(() => this.validateCode());
        },
        () => {
          this.loadingBarService.complete();
        }
      );
  }

  loadForm() { 
    this.route.paramMap
      .pipe(
        map((params) => {
          this.vehicleBillingId = Number(params.get('id'));
        })
      )
      .subscribe();
  }

  onChangeCheckReadTerms() {
    if (this.checkLinkedVehicles && this.checkReadTerms) {
      this.disableAccept = false;
      this.btnClass = "btn btn-primary";
    } else {
      this.disableAccept = true;
      this.btnClass = "btn btn-outline-primary";
    }
  }

  onScroll() {

  }

  submitForm() {

  }


  validateCode() {
    from(Swal.fire({
      title: `${this.translateService.instant('Validation Code')}`,
      html: `
      <style>
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
      </style>
      <input type="text" placeholder="${this.translateService.instant('Enter Email')}" id="swal-input1" class="swal2-input">
      <input type="number" placeholder="${this.translateService.instant('Enter Validation Code')}" id="swal-input2" class="swal2-input">`,
      inputPlaceholder: this.translateService.instant('Enter Validation Code'),
      showCancelButton: true,
      cancelButtonText: `${this.translateService.instant('Cancel')}`,
      confirmButtonColor: '#004fbc',
      cancelButtonColor: '#c4001a',
      confirmButtonText: `${this.translateService.instant('Validate')}`,
      allowOutsideClick: true,
      preConfirm: () => {
        return [
          (<HTMLInputElement>document.getElementById('swal-input1')).value,
          (<HTMLInputElement>document.getElementById('swal-input2')).value,
        ];
      },
    })).pipe(
      switchMap(result => {
        if (result.isConfirmed) {
          this.loadingBarService.start();
          this.emailOrPhone = result.value![0];
          this.code = result.value![1];
          return this.deliveryContractService.getVehicleBillingById(this.vehicleBillingId, this.emailOrPhone, this.code).pipe(
            tap( {
              next: (response) => {
                this.vehicleBilling = response;
                this.hasLinkedVehicles = response.linkedVehicles?.length > 0;
                if (this.hasLinkedVehicles) {
                  this.linkedVehicleIds = response.linkedVehicles?.map(x => x.id);
                  this.linkedVehicleString = response.linkedVehicles?.map(x => x.chassiFull).join(', ');
                } else {
                  this.checkLinkedVehicles = true;
                }
                this.checkListItens = response.checklistsItens;
                this.Response = response.checklistResponse;
                this.checkResponse();
                this.deliveryContractService.getPdf(response.id, this.emailOrPhone, this.code).subscribe((res) => {
                  let binary_string = window.atob(res as any);
                  let len = binary_string.length;
                  let bytes = new Uint8Array(len);
                  for (let i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                  }
                  this.pdfSource = bytes;
                  this.loadingBarService.complete();
                })
              },
              error: (error)=>{
                log.error('Ocorreu um erro no servidor, tente mais tarde.', error);
                this.toastr.error(error.error).onHidden.subscribe(() => this.validateCode());
                this.loadingBarService.complete();
              }
            })
          )
        } else {
          this.router.navigateByUrl('/');
          return of(null);
        }
      }),

      switchMap(result => {
          let validateCode = {
            id: this.vehicleBillingId,
            emailOrPhone: this.emailOrPhone,
            code: this.code,
            isValid: false,
            message: "",
          };
          return this.deliveryContractService.validateCode(validateCode).pipe(
            tap(response => {
              if (response.isValid) {
                this.isPhone = !this.emailOrPhone.includes("@");
                this.valid = !this.valid;
              } else {
                this.toastr.error(response.message).onHidden.subscribe(() => this.validateCode());
              }
            }),
            catchError(() => {
              log.error('Ocorreu um erro no servidor, tente mais tarde.');
              return of(null);
            })
          );
      })
    ).subscribe();
  }

  checkResponse() {
    if (this.Response > 0) this.onCheckAll();
    if (this.Response < 0) this.onUncheckAll();
  }

  onUncheckAll() {
    this.checkListItens?.forEach(element => {
      element.marked = false;
    });
  }

  onCheckAll() {
    this.checkListItens?.forEach(element => {
      element.marked = true;
    });
  }

  async onCreateChecklistPopUp() {
    let checkItemModal = this.modalService.open(ContractChecklistComponent, {
      size: 'md',
      centered: true,
    })
    checkItemModal.componentInstance.CheckItem = this.vehicleBilling.checklistsItens;
    checkItemModal.componentInstance.Response = this.vehicleBilling.checklistResponse;
    checkItemModal.componentInstance.checkResponse();
    this.viewChecklist = false;
  }

  onAccept() {
    this.getCheckList = false;
    Swal.fire({
      title: `${this.translateService.instant('Confirm Accept')}`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: `${this.translateService.instant('Cancel')}`,
      confirmButtonColor: '#004fbc',
      cancelButtonColor: '#c4001a',
      confirmButtonText: `${this.translateService.instant('Yes')}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loadingBarService.start();
        Swal.disableButtons();
        this.linkedVehicleIds.push(this.vehicleBillingId);
        let body = {
          ids: this.linkedVehicleIds,
          lat: this.latitude,
          long: this.longtude,
          isPhone: this.isPhone,
        };
        this.deliveryContractService.deliveryVehicleBilling(body, this.vehicleBillingId, this.emailOrPhone, this.code)
          .subscribe(
            (response: any) => {
              this.loadingBarService.complete();
              this.toastr.success(this.translateService.instant("Signed"))
                .onHidden.subscribe(() => this.router.navigateByUrl(`/technical-delivery/contract-accepted/${this.vehicleBillingId}`));
            });
      }
    });
  }
}
