import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { PagedResponse } from '@app/services/griddatasource.service';
import { environment } from '@env/environment';
import { map, catchError, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { VehicleSegment } from '../models/vehicle-segment.model';


@Injectable({
  providedIn: 'root'
})
export class TechnicalDeliveryService extends BaseService {
  private apiEndpoint: string = environment.technicalDeliveryServerUrl;

  constructor(private http: HttpClient, handler: HttpBackend) {
    super();
  }

  getAllVehicleSegments(): Observable<VehicleSegment[]> {
    return this.http
      .get<VehicleSegment[]>(
        `${this.apiEndpoint}/vehicle-segments/all`
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleSegments(filter: string, page: number, pageSize: number): Observable<PagedResponse<VehicleSegment>> {
    return this.http
      .get<PagedResponse<VehicleSegment>>(
        `${this.apiEndpoint}/vehicle-segments?Page=${page}&PageSize=${pageSize}&Filter=${filter}`
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  postVehicleSegment(vt : VehicleSegment) : Observable<VehicleSegment>{
    return this.http
      .post<VehicleSegment>(`${this.apiEndpoint}/vehicle-segments`, vt)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  putVehicleSegment(id: number, vs: VehicleSegment) : Observable<VehicleSegment>{
    return this.http
      .put<VehicleSegment>(`${this.apiEndpoint}/vehicle-segments/${id}`, vs)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleSegment(id:any) : Observable<VehicleSegment>{
    return this.http
      .get<VehicleSegment>(`${this.apiEndpoint}/vehicle-segments/${id}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  deleteSegment(id: number) : Observable<VehicleSegment>{
    return this.http
      .delete<VehicleSegment>(`${this.apiEndpoint}/vehicle-segments/${id}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }


}
